<template>
  <div>
    <section class="page-top">
      <transition :name="exclusiontext">
        <h4>Scroll Down</h4>
      </transition>
    </section>

    <section id="portfolio-section">

      <section id="portfolio-main">

        <router-view v-slot="{ Component, route }">
          
          <transition :name="route.meta.transition || 'pfindex'" mode="out-in">
            <component :is="Component" />
          </transition>
        </router-view>

      </section>
    </section>
    <footer-info class="footer-back" />
  </div>
</template>

<script>
import FooterInfo from "../components/FooterInfo.vue"

export default {
  name: "Portfolio",
  components: {
    FooterInfo,
  },
  data() {
    return {
      items: []
    }
  },

}
</script>

<style lang="scss">



</style>